import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const pageClass = 'page-project';

const accent_color = '#F29FC5';
const footerRelatedLinks = [
	{
		title: 'Ecole Technique de la Vallée de Joux',
		url: '/fr/projets/ecole-technique-de-la-vallee-de-joux/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectAckTypoPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: { eq: "projects/vidy/img-VIDY-cover.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/vidy/img-VIDY-cover-mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: {
						eq: "projects/vidy/SUPT_Portfolio-Vidy-image-1.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: {
						eq: "projects/vidy/SUPT_Portfolio-Vidy-image-2.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: {
						eq: "projects/vidy/SUPT_Portfolio-Vidy-image-3.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: {
						eq: "projects/vidy/SUPT_Portfolio-Vidy-image-4.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: {
						eq: "projects/vidy/SUPT_Portfolio-Vidy-artiste.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/en/projects/theatre-de-vidy-lausanne/',
					lang: 'en',
				},
			]}
			title="Théâtre de Vidy-Lausanne - Par Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Projets',
					link: '/fr/projets/',
				}}
				title="Théâtre de Vidy-Lausanne"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Projet">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Raconter l’esprit créatif et le foisonnement d’idées
							du théâtre sur un site efficace et simple.
						</p>

						<ListText
							className="element-spacing"
							title="Nos services"
							list={[
								'Conception UX',
								'Design',
								'Développement web',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>Le challenge</h3>
						<p>
							Le site du Théâtre de Vidy présente la volonté de
							refléter à la fois l’esprit créatif et vivant de la
							culture théâtrale et son foisonnement d’idées et de
							prestations. Il doit permettre non seulement de
							trouver un spectacle, mais aussi de se laisser
							emporter par la découverte, ce qui le pousse à être
							facile à utiliser tout en proposant un archivage de
							milliers d’anciens spectacles. Résumer la
							problématique serait de réussir à associer la
							simplicité et la complexité sur ce site.
						</p>

						<h3>La réponse</h3>
						<p>
							À la suite du travail d’architecture de
							l’information que le théâtre a mandaté, nous avons
							collaboré de manière intensive avec l’équipe de Vidy
							pour concevoir et créer un design communiquant le
							meilleur des deux mondes.
						</p>
					</div>
				</div>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>
							Un foisonnement créatif, de l’espace et des couches
						</h3>
						<p>
							Visuellement très rythmé et vivant, le design
							s’inspire du foisonnement créatif du théâtre. Cet
							esprit créatif s’exprime par une composition
							déstructurée par moments, et surtout rythmée et
							contrastée.
						</p>
						<p>
							Le design joue avec les superpositions, permettant
							de générer de la profondeur pour communiquer le jeu
							de l’espace.
						</p>
					</div>

					<div className="column__media text-content">
						<Video src="1f6a1c2a005664d8ef3c77f326a299ac" alt="" />
					</div>
				</Column>

				<Gallery className="col-full" modifiers="gutter">
					<Image
						{...data.image_1.childImageSharp}
						alt="Site en mobile"
					/>
					<Image
						{...data.image_2.childImageSharp}
						alt="Site en mobile"
					/>
				</Gallery>

				<Blockquote
					modifiers="big"
					uptitle=""
					quote="Les teintes pour communiquer les contextes et proposer des interactions à l’utilisateur."
					more="Les sections noires proposent une rupture graphique nette avec le reste du site. Ces sections s’ouvrent pour raconter une histoire, permettant au visiteur de plonger dans l’univers du spectacle, comme s’ils étaient dans un théâtre."
					cite=""
				/>

				<Video
					className="col-full"
					src="31904e139cc59f53146a531ad43a8b6c"
					alt=""
				/>

				<Video
					className="col-full grid-spacing"
					src="a432adea4c59bbb1670369052258bd54"
					alt=""
				/>

				<Video
					className="col-full"
					src="d4435cf9e2bd2bb3869e50d92b4963da"
					alt=""
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image
						{...data.image_3.childImageSharp}
						alt="Site en mobile"
					/>
					<Image
						{...data.image_4.childImageSharp}
						alt="Site en mobile"
					/>
				</Gallery>

				<Image
					{...data.image_5.childImageSharp}
					alt="Page détail d'un artiste"
				/>
			</section>
		</Layout>
	);
};

export default ProjectAckTypoPage;
